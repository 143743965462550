import { useAuth } from "@contexts/AuthContext";
import { useRouter } from "next/router";
import {
  ACTION_CURRENCY,
  ACTION_LANGUAGE,
  ACTION_LOGOUT,
  ACTION_SIGIN,
  ACTION_MY_ACCOUNT,
  ACTION_TRAVEL_PROFILE,
  ACTION_MY_TRIPS,
  ACTION_BOOKING_HISTORY,
  ACTION_BOOKING_OTHER_ORG,
  COOKIE_BOOKING_OTHER_ORG,
  HEARDER_V3,
  ACTION_SIGNUP,
  ACTION_REDIRECT_EXTERNAL_URL_WITH_DATA,
} from "@data/menuItems";
import { useMain } from "@contexts/mainContext";
import { configStore } from "@store/configStore";
import { useMyVacationReservationStore } from "@store/useMyVacationReservationStore";
import { myReservationReedirectionProfile } from "@hooks/useMyVacationReservation";
import { removeCookies, setCookies } from "cookies-next";
import { useLayoutStore } from "@store/layoutStore";
import { getUrlToRedirect } from "@helpers/pages";

export const useMenuItemsAction = () => {
  const { logout } = useAuth();
  const { currentUser } = useAuth();
  const { functSetShowModalSignIn, setTabSelected } = useMain();
  const { getMyReservation } = useMyVacationReservationStore();
  const { setShowCurrencyModal, setShowLanguageModal } = configStore();
  const { headerVersion } = useLayoutStore((state) => ({
    headerVersion: state.externalConfig?.headerVersion || "v1",
  }));

  const { setShowModalSignIn, setShowModalSignUp } = useLayoutStore();

  const router = useRouter();

  const handleLogout = () => {
    logout();
    router.push("/");

    // refresh the page
    if (typeof window !== "undefined") {
      window.location.reload();
    }
  };

  const handleSignin = () => {
    if (headerVersion === HEARDER_V3) {
      setShowModalSignIn(true);
    } else {
      functSetShowModalSignIn(true);
    }
  };

  const handleSignup = () => {
    setShowModalSignUp(true);
  };

  const handleShowModalCurrency = () => {
    setShowCurrencyModal(true);
  };

  const handleShowModalLanguage = () => {
    setShowLanguageModal(true);
  };

  const handleMyAccount = () => {
    /* ENDLESS REEDIRECTION */
    if (myReservationReedirectionProfile(getMyReservation())) {
      return null;
    }
    router.push({
      pathname: "/user-details",
    });
    setTabSelected("profile");
  };

  const handleTravelProfile = () => {
    router.push({
      pathname: "/user-details",
    });
    setTabSelected("travelProfile");
  };

  const handleMyTrips = () => {
    router.push({
      pathname: "/user-details",
    });
    setTabSelected("myTrips");
  };

  const handleBookingHistory = () => {
    router.push({
      pathname: "/user-details",
    });
    setTabSelected("bookingHistory");
  };

  const handleBookingOtherOrg = (data?: ItemMenu) => {
    if (typeof data == "object" && data?.actionParams) {
      const actionParams = data?.actionParams;
      const url = data?.url || "";
      const service = actionParams?.service || "";

      setCookies(COOKIE_BOOKING_OTHER_ORG, service);
      router.push({
        pathname: url,
      });
    }
  };

  const handleRedirectExternalUrlWithData = async (data?: ItemMenu) => {
    const pathUrl = await getUrlToRedirect(data?.url, currentUser);
    if (pathUrl) {
      window.open(pathUrl, "_self");
      return;
    }
    router.push(pathUrl);
  };

  const removeOrgIdInitbookCookie = () => {
    removeCookies(COOKIE_BOOKING_OTHER_ORG);
  };

  const getAction = (key: string, data?: ItemMenu) => {
    removeOrgIdInitbookCookie();

    switch (key) {
      case ACTION_LOGOUT:
        return handleLogout();
      case ACTION_SIGIN:
        return handleSignin();
      case ACTION_CURRENCY:
        return handleShowModalCurrency();
      case ACTION_SIGNUP:
        return handleSignup();
      case ACTION_LANGUAGE:
        return handleShowModalLanguage();
      case ACTION_MY_ACCOUNT:
        return handleMyAccount();
      case ACTION_TRAVEL_PROFILE:
        return handleTravelProfile();
      case ACTION_MY_TRIPS:
        return handleMyTrips();
      case ACTION_BOOKING_HISTORY:
        return handleBookingHistory();
      case ACTION_BOOKING_OTHER_ORG:
        return handleBookingOtherOrg(data);
      case ACTION_REDIRECT_EXTERNAL_URL_WITH_DATA:
        return handleRedirectExternalUrlWithData(data);
      default:
        return () => {};
    }
  };

  return { getAction, removeOrgIdInitbookCookie };
};
